import { useBaseStore } from '~/stores/base-store'

export const switchLanguage = (lang: string) => {
  useBaseStore().language = lang
  window.location.reload()
}

export const localeFlags: Record<string, string > = {
  'cs': '🇨🇿',
  'sk': '🇸🇰',
  'en': '🇬🇧',
  'es': '🇪🇸',
}

export const useLanguageTranslation = () => {
  const { $i18n } = useNuxtApp()
  const languageTranslation: Record<string, string> = {
    'cs': $i18n.t('components.sidebar.lang_switch.languages.cs'),
    'sk': $i18n.t('components.sidebar.lang_switch.languages.sk'),
    'en': $i18n.t('components.sidebar.lang_switch.languages.en'),
    'es': $i18n.t('components.sidebar.lang_switch.languages.es'),
  }

  return {
    languageTranslation,
  }
}
